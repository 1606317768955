.slider .slick-dots {
  display: flex !important;
  gap: 4px; }
  @media only screen and (max-width: 1239px) {
    .slider .slick-dots {
      margin: 5px auto 0; } }
  @media only screen and (min-width: 1240px) {
    .slider .slick-dots {
      position: absolute;
      bottom: 44px;
      left: 36px; } }
  .slider .slick-dots li {
    display: block; }

.slider .slick-dots-btn {
  padding: 5px 0; }
  .slider .slick-dots-btn:after {
    content: '';
    display: block;
    width: 24px;
    height: 3px;
    border-radius: 100px; }
    @media only screen and (max-width: 1239px) {
      .slider .slick-dots-btn:after {
        background: #D5E1EB; } }
    @media only screen and (min-width: 1240px) {
      .slider .slick-dots-btn:after {
        background: #FFF;
        opacity: 0.3; } }

@media only screen and (max-width: 1239px) {
  .slider .slick-active .slick-dots-btn:after {
    background: #5040ED; } }

@media only screen and (min-width: 1240px) {
  .slider .slick-active .slick-dots-btn:after {
    opacity: 1; } }

@media only screen and (max-width: 1239px) {
  .slider .slick-slide:first-child {
    padding-left: 20px; }
  .slider .slick-slide:not(:first-child) {
    padding-left: 8px; } }

.slider .main-slider {
  display: flex;
  flex-direction: column; }
  @media only screen and (min-width: 1240px) {
    .slider .main-slider .slick-list {
      width: 522px; } }
  .slider .main-slider .slick-list > .slick-track > .slick-slide:not(.slick-active) {
    pointer-events: none; }

/*
@include respond-to(handhelds) {
}
@include respond-to(wide-screens) {
}
*/
