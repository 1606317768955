@import "../../assets/css/variables";
@import "../../assets/css/mixins.scss";

.cookie-popup {
  position: fixed;
  background: #FFF;
  box-shadow: 4px 4px 8px 0 rgba(37, 38, 93, 0.08), -4px -4px 8px 0px rgba(37, 38, 93, 0.08);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  z-index: 999;
  @include respond-to(handhelds) {
    left: 0;
    right: 0;
    bottom: 0;
    padding: 16px;
  }
  @include respond-to(wide-screens) {
    left: 20px;
    bottom: 20px;
    width: 512px;
    height: 200px;
    border-radius: 8px;
    padding: 24px;
  }
  .svg-icon {
    display: block;
  }

  .message {
    color: #000;
    font-size: 12px;
    line-height: 18px;
  }
  .link {
    font-weight: 700;
    text-decoration-line: underline;
    white-space: nowrap;
  }
  .buttons {
    display: flex;
    align-self: stretch;
    gap: 12px;
    @include respond-to(handhelds) {
      flex-direction: column-reverse;
      margin-top: 16px;
    }
    @include respond-to(wide-screens) {
      margin-top: auto;
    }
  }
  .button {
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    border-radius: 100px;
    height: 44px;
    padding: 1px 10px 0;
    font-size: 16px;
    font-weight: 700;
    &.type1 {
      color: #5040ED;
      background-color: transparent;
      @include respond-to(handhelds) {
        flex-basis: 0;
      }
    }
    &.type2 {
      color: #5040ED;
      border: 1px solid #5040ED;
      background: #FFF;
      flex-grow: 1;
      @include respond-to(wide-screens) {
        flex-basis: 0;
      }
    }
    &.type3 {
      color: #fff;
      background: #5040ED;
      flex-grow: 1;
      @include respond-to(wide-screens) {
        flex-basis: 0;
      }
    }
  }
  .titleBlock {
    display: flex;
    align-self: stretch;
    justify-content: space-between;
    align-items: center;
  }
  .title {
    color: #000;
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
  }
  .checkboxBlock {
    @include respond-to(handhelds) {
      display: grid;
      gap: 12px;
      grid-template-columns: repeat(2, 1fr);
      margin-top: 16px;
    }
    @include respond-to(wide-screens) {
      display: flex;
      justify-content: space-between;
      margin-top: 20px;
    }
  }
  .checkboxItem {
    display: flex;
    align-items: center;
  }
  .hiddenCheckbox {
    display: none;
  }
  .checkboxLabel {
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
  }
  .checkboxTitle {
    color: #000;
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
    margin-top: 1px;
  }
  .checkboxTitle_disabled {
    opacity: 0.56;
  }
}

/*
@include respond-to(handhelds) {
}
@include respond-to(wide-screens) {
}
*/
