@import "./variables.scss";

@mixin respond-to($media) {
  @if $media == handhelds {
    @media only screen and (max-width: $breakpoint - 1) { @content; } //984
  }
  @else if $media == wide-screens {
    @media only screen and (min-width: $breakpoint) { @content; } //985
  }
}

@mixin placeholder {
  &.placeholder { @content; }
  &:-moz-placeholder { @content; }
  &::-moz-placeholder { @content; }
  &:-ms-input-placeholder { @content; }
  &::-webkit-input-placeholder { @content; }
  &::-webkit-text-fill-color { @content; }
}

@mixin retina($pixel_ratio) {
    @if $pixel_ratio == x2 {
        @media
        only screen and (-webkit-min-device-pixel-ratio: 2),
        only screen and (min-resolution: 192dpi),
        only screen and (min--moz-device-pixel-ratio: 2),
        only screen and (-o-min-device-pixel-ratio: 2/1),
        only screen and (min-device-pixel-ratio: 2),
        only screen and (min-resolution: 2dppx) {
            @content;
        }
    }
    @else if $pixel_ratio == x1 {
        @content;
    }
}
//@include retina(x2) {};
